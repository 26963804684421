import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
// import Grid from "@mui/material/Unstable_Grid2";
import { Checkbox, Divider, FormControlLabel, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Form, Header } from "semantic-ui-react";
import * as Yup from "yup";
import UserIcon from "../../Assets/iconuser.svg";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import ForgotPasswordPopup from "../../components/ForgotPasswordPopup/ForgotPasswordPopup";
import { VerificationCode } from "../../components/VerificationCode/VerificationCode";
import { useCognitoAwsV2 } from "../../Hooks/useCognitoAwsv2";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { FirstResponderData } from "../../Interfaces/FirstResponder";
import { LayoutSignAdmin } from "../../Layout/layout-sign-admin/layout-sign-admin";
import { CognitoAuthService } from "../../Services/CognitoAuthService";
import { sendVerificationCodeMFA } from "../../Services/emailService";
import {
  getFirstResponderByEmail,
  getFirstResponderByUsername,
  updateFirstResponderbyId,
} from "../../Services/firstResponderService";
import styles from "./Agency/createAgency.module.css";
import {
  REMEMBER_COOKIE_KEY_ADMIN,
  getCookie,
  setCookie,
} from "../../utils/app";
import { FR_ROLE, FR_STATUS } from "../../utils/constants";

const SignInAdmin: FC<any> = () => {
  const [rememberMe, setRememberMe] = useLocalStorage(
    "FirstResponderAdminRememberMe",
    false
  );
  const [userName, setUserName] = useLocalStorage("UserNameRememberMe", "");
  const [passWord, setPassWord] = useLocalStorage("PasswordRememberMe", "");
  const cognitoAuthService = new CognitoAuthService();
  const { error, signIn, forgotPassword } = useCognitoAwsV2();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [openForgotPasswordPopup, setOpenForgotPasswordPopup] = useState(false);
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [userFirstResponder, setUserFirstResponder] =
    useState<FirstResponderData>();

  const [values, setValues] = useState({
    password: "",
    confPassword: "",
    showPassword: false,
    showConfPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfPassword = () => {
    setValues({ ...values, showConfPassword: !values.showConfPassword });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const goTo = (route: string) => {
    navigate(route);
  };

  const generateCustomCode = async (email: string) => {
    const customCode = Math.floor(10000 + Math.random() * 90000);
    await getFirstResponderByEmail(email)
      .then(async (res) => {
        let firstResponder = res.data[0];
        if (firstResponder.mfa) {
          await updateFirstResponderbyId(firstResponder.id, {
            mfaCode: String(customCode),
          })
            .then(async (res: any) => {
              console.log(res);
              setUserFirstResponder(res);
              await sendVerificationCodeMFA(
                firstResponder.email,
                String(customCode)
              )
                .then(async (res: any) => {
                  setOpenVerificationDialog(true);
                })
                .catch(async (err: any) => {
                  alert("Error sending the verification code.");
                  setIsLoading(false);
                });
              console.log(res.mfaCode);
              // setMFACode(res.mfaCode);
              setIsLoading(false);
            })
            .catch((err: any) => {
              console.log(err);
              setIsLoading(false);
              // setErrorMessage("The Code was not generated");
              // setOpen(true);
            });
        } else {
          goTo("manageUsers");
        }
      })
      .catch(async (err) => {
        alert("The username does not exist");
      });
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      username: rememberMe ? userName : "",
      password: rememberMe ? passWord : "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required(),
      // ** Password policy is removed to allow agency owner temporary password
      password: Yup.string().required("Password is a required field"),
      // .min(8, "Must be 8 characters or more")
      // .matches(/[A-Z]+/, "One uppercase character")
      //.matches(/[a-z]+/, "One lowercase character")
      //.matches(/\d+/, "One number")
      //.matches(/[!@#$%^&*]+/, "One special case character"),
    }),

    onSubmit: async (formData) => {
      try {
        setIsLoading(true);
        const { username, password } = formData;

        if (!rememberMe) {
          setRememberMe(false);
        } else {
          // setUserName(username)
          // setPassWord(password)
        }

        //TODO: Get email by username
        const result = await getFirstResponderByUsername(String(username));
        const fr = result.data[0] as FirstResponderData;
        if (!fr || !fr.email) {
          alert("The username does not exist or is incorrect.");

          return;
        }

        if (fr.status === FR_STATUS.BLOCKED) {
          alert("The user is blocked.");
          return;
        }

        if (
          fr.status === FR_STATUS.ACTIVE &&
          fr.rol === FR_ROLE.FIRST_RESPONDER_OWNER &&
          fr.subCognitoId === null
        ) {
          navigate("/admin/confirmAgency", {state:{username}});
          return;
        }

        await signIn(fr.email, password)
          .then(async (res: any) => {
            console.log(
              "🚀 ~ file: LoginAdmin.tsx ~ line 140 ~ .then ~ res",
              res
            );
            generateCustomCode(res.attributes.email);

            if (rememberMe) {
              setCookie(REMEMBER_COOKIE_KEY_ADMIN, username);
            }
          })
          .catch(async (err) => {
            alert(err.message);
          });
      } catch (err) {
        console.log("🚀 ~ onSubmit: ~ err:", err);
      } finally {
        setIsLoading(false);
      }
    },
  });

  // Looks for stored user on cookies
  useEffect(() => {
    (() => {
      const storedUser = getCookie(REMEMBER_COOKIE_KEY_ADMIN);
      if (storedUser) {
        formik.setFieldValue("username", storedUser);
        setRememberMe(true);
      }
    })();
  }, []);

  return (
    <>
      <LayoutSignAdmin
        children={
          <Box sx={{ flexGrow: 1, color: "#000" }}>
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid container spacing={3} rowSpacing={2.5} direction="row">
                <Grid item>
                  <Header as="h1" style={{ fontSize: "35px" }}>
                    Login
                  </Header>
                </Grid>
                <Grid item xs={12}>
                  <p style={{ fontSize: "16px" }}>
                    Login to enter the First Responder Admin Dashboard
                  </p>
                  {/* {JSON.stringify(error)} */}
                </Grid>
                <Grid item xs={12}>
                  <label className={styles.labelFields}>Username</label>
                  <Form.Input
                    size="big"
                    placeholder="Required"
                    type="text"
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={formik.errors.username && true}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <label className={styles.labelFields}>Password</label>
                  <Input
                    name="password"
                    type={values.showPassword ? "text" : "password"}
                    className={styles.passwordField}
                    inputProps={{
                      style: {
                        fontSize: "1.42857143em",
                        borderRight: "none",
                        borderTop: "none",
                      },
                    }}
                    placeholder="Required"
                    onChange={handlePasswordChange("password")}
                    onChangeCapture={formik.handleChange}
                    value={formik.values.password}
                    autoComplete="off"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? (
                            <MdVisibility style={{ color: "#000094" }} />
                          ) : (
                            <MdVisibilityOff style={{ color: "#000094" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={formik.errors.password && true ? true : false}
                    required
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <FormControlLabel
                      label="Remember Me"
                      control={
                        <Checkbox
                          checked={rememberMe}
                          onChange={() => setRememberMe(!rememberMe)}
                          name="rememberme"
                          sx={{
                            color: "#000094",
                            "&.Mui-checked": {
                              color: "#000094",
                            },
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item onClick={() => setOpenForgotPasswordPopup(true)}>
                    <Typography
                      style={{
                        color: "#000094",
                        fontSize: 15,
                        fontWeight: 400,
                        cursor: "pointer",
                      }}
                    >
                      Forgot Password or Username?
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <CustomButton
                    className={styles.buttonStyleDiv}
                    style={{
                      background: "#000094",
                      color: "white",
                      fontSize: "19px",
                      padding: "23px",
                    }}
                    text="Login"
                    // disabled={!readyToSubmit()}
                    isLoading={isLoading}
                    type="submit"
                    sx={{ mt: 5, mb: 1 }}
                    onClick={() => formik.handleSubmit()}
                  />
                  {/* <Form.Button
                    className={styles.buttonStyleDiv}
                    type="submit"
                    style={{
                      background: "none",
                      color: "white",
                      fontSize: "19px",
                      padding: "23px",
                    }}
                    onClick={() => formik.handleSubmit}
                  >
                    Login
                  </Form.Button> */}
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ color: "#999999", width: "100%" }}>
                    Or
                  </Divider>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <label className={styles.labelAlreadyAccount}>
                    Don&rsquo;t have an account yet?
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <Form.Button
                    onClick={() => goTo("createagency")}
                    className={styles.buttonStyleDivWhite}
                    type="button"
                    style={{
                      background: "none",
                      color: "#000094",
                      fontSize: "19px",
                      padding: "23px",
                    }}
                  >
                    Create an Agency
                  </Form.Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ color: "#999999" }}>
                    Not an Agency Administrator?
                  </Divider>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing="7"
                >
                  <Grid item>
                    <img src={UserIcon} height={19} width={14} />
                  </Grid>
                  <Grid item>
                    <Typography
                      onClick={() => goTo("/")}
                      component="span"
                      style={{
                        color: "#000094",
                        fontWeight: 500,
                        fontSize: 15,
                        cursor: "pointer",
                      }}
                    >
                      Login as First Responder
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Box>
        }
      ></LayoutSignAdmin>
      <VerificationCode
        user={userFirstResponder}
        open={openVerificationDialog}
        handleClose={() => setOpenVerificationDialog(false)}
        handleCode={() => generateCustomCode(String(userFirstResponder?.email))}
      />
      <ForgotPasswordPopup
        open={openForgotPasswordPopup}
        handleClose={() => setOpenForgotPasswordPopup(false)}
      />
    </>
  );
};

export default SignInAdmin;
