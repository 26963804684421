import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { TbCircleCheck, TbClock } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Header } from "semantic-ui-react";
import * as Yup from "yup";
import PrincipalLayout from "../../../components/Common/PrincipalLayout";
import { CustomButton } from "../../../components/CustomButton/CustomButton";
import { FirstResponderData } from "../../../Interfaces/FirstResponder";
import { CognitoAuthService } from "../../../Services/CognitoAuthService";
import {
  getFirstResponderByUsername,
  updateFirstResponderbyId,
} from "../../../Services/firstResponderService";
import styles from "./createAgency.module.css";
import ModalTermsAndConditions from "./termAndConditions";
import { FR_ROLE } from "../../../utils/constants";

const ConfirmAgencyOwner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { username } = location.state || {};
  const cognitoAuthService = new CognitoAuthService();
  const [user, setUser] = useState<FirstResponderData | null>(null);
  const [success, setSuccess] = useState(false);
  const [expired, setExpired] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState<null | string>(null);
  const [isChecked, setIsChecked] = useState(false);

  const handleAgree = (
    isChecked: boolean | ((prevState: boolean) => boolean)
  ) => {
    setIsChecked(isChecked);
  };

  useEffect(() => {
    if (username) {
      getFirstResponderByUsername(username).then((res) => {
        if (!res.data[0]) return;
        setUser(res.data[0]);
      });
    }
  }, [username]);

  const [values, setValues] = useState({
    password: "",
    confPassword: "",
    showPassword: false,
    showConfPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfPassword = () => {
    setValues({ ...values, showConfPassword: !values.showConfPassword });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const formik = useFormik({
    initialValues: {
      firstname: user?.firstName || "",
      lastname: user?.lastName || "",
      username: user?.username || "",
      password: "",
      confirmpassword: "",
    },
    validationSchema: Yup.object({
      //firstname: Yup.string().required(),
      //  lastname: Yup.string().required(),
      // username: Yup.string().required(),
      password: Yup.string()
        .required("Password is a required field")
        .min(8, "Must be 8 characters or more")
        .matches(/[A-Z]+/, "One uppercase character")
        .matches(/[a-z]+/, "One lowercase character")
        .matches(/\d+/, "One number")
        .matches(/[!@#$%^&*]+/, "One special case character"),
      confirmpassword: Yup.string()
        .required("Confirm Password is a required field")
        .oneOf([Yup.ref("password")], "Please make sure your passwords match."),
    }),
    onSubmit: async (formData) => {
      if (!user) return;

      if (user.rol !== FR_ROLE.FIRST_RESPONDER_OWNER) {
        navigate("/");
        return;
      }

      const { password } = formData;
      cognitoAuthService
        .signUp(user.email, password, user.firstName, user.lastName)
        .then(async (res: any) => {
          const fp = await updateFirstResponderbyId(user?.id, {
            // firstName: firstname,
            // lastName: lastname,
            // username,
            subCognitoId: res.userSub,
          });
          if (fp) {
            setSuccess(true);
          }
        });
    },
  });

  const confirmSignUp = async () => {
    if (!code || !user) {
      setError("Please enter the code");
      return;
    }

    cognitoAuthService
      .confirmSignUp(user.email, code)
      .then(() => {
        updateFirstResponderbyId(user.id, {
          status: "active",
        });
        navigate("/admin");
      })
      .catch((reason) => {
        if (reason.code === "CodeMismatchException") {
          setError("Invalid code");
          return;
        }
        setError("Something went wrong, please try again later");
      });
  };

  if (!user) return <div>Loading...</div>;

  formik.initialValues.lastname = user.lastName;
  formik.initialValues.firstname = user.firstName;

  return (
    <PrincipalLayout revert={expired}>
      <Box sx={{ flexGrow: 1, color: "#000", py: 6, px: 11 }}>
        {success ? (
          <Grid container mt={expired ? 32 : 16}>
            <Grid xs={12}>
              <div className={styles.sucessIcon}>
                {expired ? (
                  <TbClock size={80} style={{ color: "#FF8642" }} />
                ) : (
                  <TbCircleCheck size={80} style={{ color: "#86E0A7" }} />
                )}
              </div>
            </Grid>
            <Grid xs={12}>
              <Header as="h1" style={{ fontSize: "25px", textAlign: "center" }}>
                {expired
                  ? "Sorry, your invitation has expired"
                  : "Account has been successfully set up!"}
              </Header>
            </Grid>
            <Grid xs={12}>
              <p
                style={{
                  fontSize: "16px",
                  color: "#384057",
                  textAlign: "center",
                }}
              >
                {expired
                  ? "Please contact the person who invited you"
                  : "Please use your Username and Password to login"}
              </p>
            </Grid>
            {!expired && (
              <>
                <Grid xs={12} mt={4} mb={2}>
                  <Header
                    as="h3"
                    style={{ fontSize: "25px", textAlign: "center" }}
                  >
                    Please enter the verification code you received via email
                  </Header>
                </Grid>
                <Grid xs={12} px={24}>
                  <TextField
                    fullWidth
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    type="text"
                    variant="outlined"
                    placeholder="Verification Code"
                  />
                </Grid>
                <Grid xs={12} mt={2} sx={{ textAlign: "-webkit-center" }}>
                  <CustomButton
                    style={{
                      margin: "14px",
                      width: "40%",
                      fontSize: "19px",
                      background: "none",
                      border: "1px solid #000094",
                      color: "#000094",
                    }}
                    text="Login"
                    onClick={confirmSignUp}
                    sx={{ mt: 5, mb: 1 }}
                  />
                </Grid>
              </>
            )}
            {error && (
              <Grid xs={12} sx={{ textAlign: "-webkit-center" }}>
                <p style={{ color: "red" }}>{error}</p>
              </Grid>
            )}
          </Grid>
        ) : (
          <Form
            autoComplete="off"
            className={styles.inputForm}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={6} rowSpacing={2}>
              <Grid maxHeight={56} xs={12}>
                <Header
                  as="subtitle"
                  style={{ fontSize: "21px", fontWeight: 500 }}
                >
                  Welcome{" "}
                  <span
                    style={{
                      color: "#000094",
                    }}
                  >
                    {user.firstName} {user.lastName} - {user.office}
                  </span>
                </Header>
              </Grid>
              <Grid maxHeight={56} xs={12}>
                <Header as="h1" style={{ fontSize: "35px" }}>
                  Complete Your Account
                </Header>
              </Grid>
              <Grid xs={12}>
                <p style={{ fontSize: "16px" }}>
                  To complete your Agency Owner account, please create a new
                  password.
                </p>
              </Grid>
              {/* <Grid xs={6}>
                <label className={styles.labelFields}>First Name</label>
                <Form.Input
                  value={formik.values.firstname}
                  size="big"
                  placeholder="Required"
                  type="text"
                  name="firstname"
                  onChange={formik.handleChange}
                  required
                  disabled
                />
                {formik.errors.firstname && (
                  <span className={styles.errorFormik}>
                    {formik.errors.firstname}
                  </span>
                )}
              </Grid>
              <Grid xs={6}>
                <label className={styles.labelFields}>Last Name</label>
                <Form.Input
                  value={formik.values.lastname}
                  size="big"
                  placeholder="Required"
                  type="text"
                  name="lastname"
                  onChange={formik.handleChange}
                  required
                  disabled
                />
                {formik.errors.lastname && (
                  <span className={styles.errorFormik}>
                    {formik.errors.lastname}
                  </span>
                )}
              </Grid> */}
              <Grid xs={6} display="flex" flexDirection={"column"}>
                <label className={styles.labelFields}>Username</label>
                <label style={{ fontSize: "21px" }}>{user?.username}</label>
              </Grid>
              <Grid xs={6} display="flex" flexDirection={"column"}>
                <label className={styles.labelFields}>Agency</label>
                <label style={{ fontSize: "21px" }}>{user?.office}</label>
              </Grid>
              <Grid xs={6}>
                <label className={styles.labelFields}>Password</label>
                <Input
                  name="password"
                  type={values.showPassword ? "text" : "password"}
                  className={styles.passwordField}
                  inputProps={{
                    style: {
                      fontSize: "1.28571429em",
                      borderRight: "none",
                      borderTop: "none",
                    },
                  }}
                  placeholder="Required"
                  onChange={handlePasswordChange("password")}
                  onChangeCapture={formik.handleChange}
                  value={values.password}
                  autoComplete="off"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <MdVisibility style={{ color: "#000094" }} />
                        ) : (
                          <MdVisibilityOff style={{ color: "#000094" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  required
                />
                {formik.errors.password && (
                  <span style={{ top: "2px" }} className={styles.errorFormik}>
                    {formik.errors.password}
                  </span>
                )}
                <br />
                <label
                  style={{
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#384057",
                  }}
                >
                  Password must contain at least 8 characters, at least one
                  capital letter, one number, and one special symbol
                </label>
              </Grid>
              <Grid xs={6}>
                <label className={styles.labelFields}>Confirm Password</label>
                <Input
                  name="confirmpassword"
                  className={styles.passwordField}
                  type={values.showConfPassword ? "text" : "password"}
                  onChange={handlePasswordChange("confPassword")}
                  onChangeCapture={formik.handleChange}
                  placeholder="Required"
                  inputProps={{
                    style: {
                      fontSize: "1.28571429em",
                      borderRight: "none",
                      borderTop: "none",
                    },
                  }}
                  value={values.confPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showConfPassword ? (
                          <MdVisibility style={{ color: "#000094" }} />
                        ) : (
                          <MdVisibilityOff style={{ color: "#000094" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  required
                />
                {formik.errors.confirmpassword && (
                  <span style={{ top: "2px" }} className={styles.errorFormik}>
                    {formik.errors.confirmpassword}
                  </span>
                )}
              </Grid>
              <Grid xs={12} sx={{ textAlign: "center" }} mt={2}>
                <label className={styles.labelFieldsTerms}>
                  By checking the box, you accept...
                  <br />
                  <ModalTermsAndConditions handleAgree={handleAgree} />
                </label>
              </Grid>
              <Grid xs={12}>
                <Form.Button
                  className={styles.buttonStyleDiv}
                  type="submit"
                  style={{
                    width: "100%",
                    background: "none",
                    color: "white",
                    fontSize: "19px",
                    padding: "23px",
                  }}
                  disabled={!isChecked}
                >
                  Continue
                </Form.Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Box>
    </PrincipalLayout>
  );
};

export default ConfirmAgencyOwner;
